import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse';
import ReCAPTCHA from 'react-google-recaptcha'
import { languageFromLocale, t, currentLanguage, RECAPTCHA_SITE_KEY } from '../utils'

import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from '../services/auth'
import { updateUserData } from '../services/database'


const Login = (props) => {
  const { startLoading, stopLoading, showSnackbar, onLogin, onRegister, fetchUserData, partner } = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationPassword, setVerificationPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [terms, setTerms] = useState(false)
  const [content, setContent] = useState('login')
  const [captchaValue, setCaptchaValue] = useState(null)

  useEffect(() => {
    if (!!props.content) {
      setContent(props.content)
    }
  }, [props.content])

  const showError = (message) => {
    showSnackbar({ text: message, color: 'error' })
  }

  const submit = async () => {
    if (!email || email === '') {
      showError(t('auth.enter_email'))
      return
    }
    if (content === 'reset') {
      submitReset()
    } else if (content === 'register') {
      submitRegister()
    } else if (content === 'login') {
      submitLogin()
    }
  }

  const submitLogin = async () => {
    if (!password || password === '') {
      showSnackbar({ text: t('auth.empty_password'), color: 'warning' })
      return
    }
    startLoading('checking')
    let result = await signInWithEmailAndPassword(email, password)
    stopLoading('checking')
    showSnackbar({ text: t('auth.login_success'), color: 'success' })
    if (result.error) {
      showError(t(`auth_error.${result.error.code}`))
      return
    }
    onLogin()
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  const submitRegister = async () => {
    if (!lastName || lastName === '') {
      showError(t('contact.empty_name_last'))
      return
    } else if (!firstName || firstName === '') {
      showError(t('contact.empty_name_first'))
      return
    } else if (!password || password === '') {
      showError(t('auth.empty_password'))
      return
    } else if (password !== verificationPassword) {
      showError(t('auth.password_mismatch'))
      return
    } else if (!terms) {
      showError(t('auth.accept_required'))
      return
    } else if (!privacy) {
      showError(t('auth.accept_required'))
      return
    }
    // if(!captchaValue) {
    //   showError(t('auth.captcha'))
    //   return 
    // }
    startLoading('creating')

    let result = await createUserWithEmailAndPassword(email, password)
    await updateUserData({
      name: {
        first: firstName,
        last: lastName
      },
      contact: {
        email: email
      },
      privacy: true,
      terms: true
    })
    fetchUserData()
    stopLoading('creating')
    showSnackbar({ text: t('auth.account_created'), color: 'success' })
    if (result.error) {
      showError(t(`auth_error.${result.error.code}`))
      return
    }
    onRegister()
  }

  const submitReset = async () => {
    startLoading('sending')
    let result = await sendPasswordResetEmail(email)
    stopLoading('sending')
    if (result.error) {
      showError(t(`auth_error.${result.error.code}`))
    } else {
      showSnackbar({ text: t('auth.reset_success'), color: 'success' })
    }
  }


  const togglePasswordReset = () => {
    if (content === 'reset') {
      setContent('login')
    } else {
      setContent('reset')
    }
  }

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  const leftTitle = () => {
    if (content === 'register') {
      return t('auth.got_account')
    } else {
      return t('auth.no_account')
    }
  }

  const leftSubtitle = () => {
    if (content === 'register') {
      return t('auth.sign_in_subtitle')
    } else {
      return t('auth.sign_up_subtitle')
    }
  }

  const leftButtonTitle = () => {
    if (content === 'register') {
      return t('auth.sign_in')
    } else {
      return t('auth.create_account')
    }
  }

  const leftButtonClicked = () => {
    if (content === 'register') {
      setContent('login')
    } else {
      setContent('register')
    }
  }

  const submitButtonTitle = () => {
    if (content === 'register') {
      return t('auth.create_account')
    } else if (content === 'reset') {
      return t('auth.reset_button')
    } else {
      return t('auth.sign_in')
    }
  }

  const passwordResetButtonTitle = () => {
    if (content === 'reset') {
      return t('general.cancel')
    } else {
      return t('auth.forgot_password')
    }
  }

  const formTitle = () => {
    if (content === 'register') {
      return t('auth.no_account')
    } else if (content === 'reset') {
      return t('auth.reset_password')
    } else {
      return t('auth.got_account')
    }
  }

  const formSubtitle = () => {
    if (content === 'register') {
      return t('auth.sign_up_subtitle')
    } else if (content === 'reset') {
      return ''
    } else {
      return t('auth.sign_in_subtitle')
    }
  }

  const Locale = () => {
    if (partner) {
      for (let i in partner.locales) {
        if (currentLanguage() === languageFromLocale(partner.locales[i]).code) {
          return partner.locales[i]
        }
      }
    }
    return 'fr_BE'
  }

  const links = [
    {
      title: t('auth.terms_of_use'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Service-voorwaarden.pdf` : `assets/fr/Conditions-d'utilisation.pdf`,
      checked: terms,
      onChange: (e) => {setTerms(e.target.checked)}
    },
    {
      title: t('auth.privacy_policy'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Privacy-beleid.pdf` : `assets/fr/Politique-vie-privée.pdf`,
      checked: privacy,
      onChange: (e) => {setPrivacy(e.target.checked)}
    }
  ]

  return (
    <div className="row row--stretch">
      <div className="col col--sign-up">
        <h3 className="sec__h3 h3--bold">{leftTitle()}<br /></h3>
        <p className="p p--small"> {leftSubtitle()} </p>
        <div onClick={leftButtonClicked} className="link-box link-box--sign-up link-box--green w-inline-block">
          <p className="link-box__title">{leftButtonTitle()}<br /></p>
        </div>
      </div>
      <div className="col col--sign-up col--sign-up--white">
        <h3 className="sec__h3 h3--bold">{formTitle()}<br /></h3>
        <p className="p p--small">{formSubtitle()}</p>
        <div className="sign-up1-form w-form">
          <div id="email-form" name="email-form" data-name="Email Form" className="form">
            <Collapse in={content === 'register'}>
              <div className="form__field-wrap form__field-wrap--stretch">
                <label htmlFor="last-name" className="form__label">{t('general.name_last')}</label>
                <input type="text" className="form__text-field w-input" maxLength="256" name="Last name" data-name="Last name" id="last-name" required="" value={lastName} onChange={e => setLastName(e.target.value)} />
              </div>
              <div className="form__field-wrap form__field-wrap--stretch">
                <label htmlFor="first-name" className="form__label">{t('general.name_first')}</label>
                <input type="text" className="form__text-field w-input" maxLength="256" name="First name" data-name="First name" id="first-name" required="" value={firstName} onChange={e => setFirstName(e.target.value)} />
              </div>
            </Collapse>
            <div className="form__field-wrap form__field-wrap--stretch">
              <label htmlFor="Email" className="form__label">{t('general.email')}</label>
              <input type="email" className="form__text-field w-input" maxLength="256" name="Email" data-name="Email" id="Email" required="" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <Collapse in={content !== 'reset'}>
              <div className="form__field-wrap sign-up form__field-wrap--stretch">
                <label htmlFor="Mot-de-passe" className="form__label">{`${t('general.password')}${content === 'register' ? ` ${t('auth.password_hint')}` : ''}`}</label>
                <input onKeyDown={onKeyDown} type="password" className="form__text-field w-input" maxLength="256" name="Mot-de-passe" data-name="Mot de passe" id="Mot-de-passe" required="" value={password} onChange={e => setPassword(e.target.value)} />
              </div>
            </Collapse>
            <Collapse in={content !== 'register'}>
              <br />
              <h6 className="h6 h6--blue reset-password-button" onClick={togglePasswordReset}>{passwordResetButtonTitle()}<br /></h6>
            </Collapse>
            <Collapse in={content === 'register'}>
              <div className="form__field-wrap sign-up form__field-wrap--stretch">
                <label htmlFor="Mot-de-passe-2" className="form__label">{t('auth.confirm_password')}</label>
                <input type="password" className="form__text-field w-input" maxLength="256" name="Mot-de-passe-2" data-name="Mot De Passe 2" id="Mot-de-passe-2" required="" value={verificationPassword} onChange={e => setVerificationPassword(e.target.value)} />
              </div>
              {links.map((link, linkIndex) => {
                return (
                  <div>
                    <label className="w-checkbox checkbox-field">
                      <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${link.checked ? 'w--redirected-checked' : ''}`}></div>
                      <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required="" checked={link.checked} onChange={e => link.onChange(e)} style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
                      <a className="checkbox-label w-form-label" href={link.link} rel="noopener noreferrer" target="_blank">{link.title}<br /></a>
                    </label>
                  </div>
                )
              })}
            </Collapse>
            <Collapse in={content === 'register'}>
            {/* <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(val) => setCaptchaValue(val)}
              hl={currentLanguage()}
            /> */}
            </Collapse>
            <div className="form__btn-wrap sign-up"><input type="submit" value={submitButtonTitle()} onClick={submit} className="sign-up-form__btn w-button" /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login