import React, { useEffect, useState } from 'react'
import { t, languageCodesFromLocales, currentLanguage, languageFromLocale } from '../utils'
import config from '../config.json'
import { Link } from '@material-ui/core'
const Footer = ({ history }) => {

  // const { refreshInteractions, partner } = props
  // const Locale = () => {
  //   if (partner) {
  //     for (let i in partner.locales) {
  //       if (currentLanguage() === languageFromLocale(partner.locales[i]).code) {
  //         return partner.locales[i]
  //       }
  //     }
  //   }
  //   return 'fr_BE'
  // }

  // const links = [
  //   {
  //     title: t('footer.cookie_policy'),
  //     link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Cookies-beleid.pdf` : `assets/fr/Politique-de-cookies.pdf`
  //   },
  //   {
  //     title: t('footer.use_website'),
  //     link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Gebruik-van-het-website.pdf` : `assets/fr/Utilisation-du-site-Web.pdf`
  //   },
  //   {
  //     title: t('footer.privacy_policy'),
  //     link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Privacy-beleid.pdf` : `assets/fr/Politique-vie-privée.pdf`
  //   },
  //   {
  //     title: t('footer.terms_of_service'),
  //     link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Service-voorwaarden.pdf` : `assets/fr/Conditions-d'utilisation.pdf`
  //   }
  // ]

  // useEffect(() => {
  //   refreshInteractions()
  // }, [])

  const navigate = (path) => {
    history.push(path)
  }

  return (
    <footer className={`footer-2 footer-2--${config.class_handle}`}>
      <div className="footer-2__top">
        <div className="container">
          <div className="content">
            <div className="logo">
              <img src="/images/footer_logo.png" alt="Beci" />
            </div>
            <ul>
              <li><Link onClick={() => history.push("/")}>{t('navbar.mle')}</Link></li>
              <li><Link onClick={() => history.push("/appointment?location=appointment")}>{t('navbar.appointment')}</Link></li>
              <li><Link onClick={() => history.push("/help")}>{t('navbar.help')}</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-2__bottom">
        <div className="container">
          <div className="content">
            <div className="copyright">
              <p>
                &copy;{new Date().getFullYear()} {t('footer.copyright')} <span className="divider">-</span> <Link to="/privacy-policy" onClick={() => navigate('/privacy-policy')}>{t('footer.privacy_policy')}</Link> <span className="divider">-</span> <Link to="/terms" onClick={() => navigate('/terms')}>{t('footer.terms_of_use')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )

  // return (
  //   <div className="sec sec--footer">
  //           <div className="container container--footer _2">
  //             <div className="row">
  //               <div className="col footer__col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><img src="images/Legal-Village.svg" alt="" className="footer__logo" /></a>
  //                 <div className="footer__link-wrap">
  //                   <a data-w-id="b3f7fd10-57a7-00e7-c6f2-6441f9710ae8" href={t('footer.legal_village_url')} className="footer-link footer-link--mr w-inline-block">
  //                     <div className="link-text">{t('footer.legal_village_button')}</div>
  //                   </a>
  //                 </div>
  //                 <div className="footer__link-wrap">
  //                   <a className="footer__link w-inline-block w--current" rel="noopener noreferrer" target="_blank">{t('footer.legal_protection')}</a>
  //                 </div>
  //                 <div className="footer__link-wrap footer__link-wrap--2">
  //                   {links.map((link, linkIndex) => {
  //                     return (
  //                       <a key={`footer_link_${linkIndex}`} className="footer__link w-inline-block w--current" href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
  //                     )
  //                   })
  //                   }
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  // )
}

export default Footer
