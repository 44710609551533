import React, { useEffect, useState } from 'react'
import { sortedArrayFromObject, t, currentLanguage } from '../utils'

const SPStepQuestion = (props) => {

  const { onQuestionSelect, selectedTopic, refreshInteractions } = props

  const [questions, setQuestions] = useState([])

  useEffect(() => {
    refreshInteractions()
  }, [questions])

  useEffect(() => {
    const qArray = sortedArrayFromObject(props.questions)
    let filteredQuestions = []
    for(let i in qArray) {
      let q = qArray[i]
      if(q.topic === props.selectedTopic) {
        filteredQuestions.push(q)
      }
    }
    // push universal at the end
    for(let i in qArray) {
      let q = qArray[i]
      if(q.topic === 'universal') {
        filteredQuestions.push(q)
      }
    }
    setQuestions([...filteredQuestions])
  }, [props.questions, props.selectedTopic])

  return (
    <div className="container container--hero">
      <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" className="h3 h3--bold h3--blue">{ `${t('appointment.question_title')}` }<br/></h3>
      <div className="faq-wrap">
        <h3 className="faq__h3 h3--bold">{ props.topics && props.topics[selectedTopic] ? props.topics[selectedTopic].title[currentLanguage()] : '' }</h3>
        <p className="category-detail-description">{ props.topics && props.topics[selectedTopic] ? props.topics[selectedTopic].description[currentLanguage()] : '' }<br/></p>
        { questions.map((question, qi) => {
          return (
            <div key={`question_${qi}`} onClick={() => onQuestionSelect(question)} className="title-link w-inline-block">
              <div className="title-wrap">
                <h4 className="h4 h4--green">{ question.text[currentLanguage()] }<br/></h4><img src="images/green-right-arrow.svg" alt="arrow-img" width="10" className="arrow-img"/>
              </div>
            </div> 
          )
        })}
      </div>
    </div>
  )
}

export default SPStepQuestion