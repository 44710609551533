import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment'

import { AddToCalendar } from '../components/AddToCalendar'
import '../components/AddToCalendar/styles.css'

import { t, currentLanguage } from '../utils'
import { GlobalContext } from '../context'

const AppointmentConfirmation = (props) => {

  const { refreshInteractions, history, partner, fetchAppointments } = props
  const { selectedGlobalExpert, setSelectedGlobalExpert } = useContext(GlobalContext)

  const [appointment, setAppointment] = useState({})
  const [calendarEvent, setCalendarEvent] = useState(null)
  const [experts, setExperts ] = useState([])
  const [expertsReady, setExpertsReady] = useState(false)
  const [expertsSelected, setExpertsSelected] = useState('all')

  useEffect(() => {
    refreshInteractions()
    const ap = history.location?.state?.appointment
    if(!ap) {
      history.replace('/')
    } else {
      setAppointment({...ap})
    }
  }, [])


  useEffect(() => {
    if(expertsReady) return 
    if(!partner) {
      return
    }
    if(partner.experts) {
      if(selectedGlobalExpert) {
        setExperts([selectedGlobalExpert])
        setSelectedGlobalExpert(null)
        setExpertsSelected('one')
      }else {
        setExperts(partner.experts)
        setExpertsSelected('all')
      }
      setExpertsReady(true)
    }
  }, [partner, selectedGlobalExpert, expertsReady, setSelectedGlobalExpert])

  useEffect(() => {
    if(!appointment) {
      return
    }
    const formattedStart = moment(appointment.date).format("YYYY-MM-DDTHH:mm:ssZ")
    const formattedEnd = moment(appointment.date).add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ssZ")
    setCalendarEvent({
      name: t('ics.title'),
      details: t('ics.description'),
      location: '',
      startsAt: formattedStart,
      endsAt: formattedEnd,
      // url: 'https://teams.microsoft.com/l/meetup-join/19:meeting_MmIwODcyMWEtMTNjNi00YTZlLTkyYTAtMjRmZmZiM2M3OTFj@thread.v2/0?context=%7B%22Tid%22:%22dda74def-98fe-45f1-922f-62a85e3861e4%22,%22Oid%22:%2233edf5a6-9990-4756-b26f-3dcae38187b6%22%7D',
      url: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZThlOWE4NDktZDA4Mi00NWVjLTg3YzktMTc1MjgwYmNkYjFi%40thread.v2/0?context=%7b%22Tid%22%3a%225fbdaf32-5428-4e97-aed2-e7ec5ca4be32%22%2c%22Oid%22%3a%2289d27457-0c45-4849-aec4-f0469d4c022a%22%7d'
    })
  }, [appointment])

  const printDate = (date) => {
    if(!date) {
      return ''
    } 
    return moment(date).format(`DD/MM/YYYY [${t('general.time_at')}] HH[h]mm`)
  }

  const navigate = (path) => () => {
    fetchAppointments()
    history.replace(path)
  }

  return (
    <div className="container container--hero">
      <div className="sign-up3-wrap">
        <div className="circles-wrap signup">
          <div className="col__cont-wrap col__cont-wrap--relative">
            { experts.map((expert, ei) => {
              return (
                <div className="expert-info-wrap" key={`expert_${ei}`}>
                  <div data-w-id="67e5e4e5-5039-31ba-307c-fa3bf17031e4" className="expert-box circle-box--3 fd" style={{ backgroundImage: `url('${expert.image}')`}}>
                    {/* <div className="expert-desc">
                      <h4 className="expert__h4">{ expert.name }<br/></h4>
                      <p className="expert__p">{ expert.bio[currentLanguage()] }</p>
                    </div> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <h1 className="h1 h1--center">{ t('appointment.confirmed_title') }<br/></h1>
        <p className="p p--small">{ t('appointment.confirmed_subtitle') }<br/>{ expertsSelected === 'all' ? t('appointment.confirmed_detail', { date: printDate(appointment?.date) }) : t('appointment.confirmed_detail_2', { date: printDate(appointment?.date) }) }</p>
        <div onClick={navigate('/appointments')} className="link-box link-box--sign-up link-box--green center w-inline-block">
          <p className="link-box__title">{ t('appointment.to_dashboard')}<br/></p>
        </div>
        { !!calendarEvent ? (
          <div className="request-cta signup dropdown_calendar">
            <AddToCalendar event={calendarEvent} className="calendar-button" filename={`${t('ics.title')}.ics`}>
              <div className="text text--small add-to-calendar-label">{ t('dashboard.save_to_calendar') }</div>
            </AddToCalendar>
          </div>
        ) : null }
      </div>
    </div>
  )
}

export default AppointmentConfirmation