import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { t, currentLanguage, safeValue, isPhoneValid, isVatValid, isEmailValid, RECAPTCHA_SITE_KEY } from '../utils'
import { updateUserData } from '../services/database'
import { getCompanyData } from '../services/functions'
import { animateScroll as scroll } from 'react-scroll'
import config from '../config.json'
import { Loader } from './'
import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/fr'

const APStepSummary= ({ topics, questions, selectedTopic, selectedQuestion, selectedDate, userData, onConfirm }) => {

  const [contactType, setContactType] = useState('') // phone or teams
  const [contactSelectionAvailable, setContactSelectionAvailable] = useState(false)

  useEffect(() => {
    if(!selectedTopic) {
      return
    }
    if(!topics[selectedTopic]) {
      return
    }
    const topic = topics[selectedTopic]
    setContactSelectionAvailable(!topic.phone_only)
  }, [topics, selectedTopic])

  const handleContactTypeChange = (type) => (e) => {
    if(e.target.checked) {
      setContactType(type)
    } else {
      setContactType('')
    }
  }

  const printDate = (date) => {
    if(!date) {
      return ''
    }
    date = moment(date)
    let day = date.format('D')
    let weekday = date.locale(currentLanguage()).format('dddd')
    let month = date.locale(currentLanguage()).format('MMMM')
    let year = date.format('YYYY')
    let time = date.format('HH:mm')
    return `${capitalizeWord(weekday)} ${day} ${month} ${year}, ${time}`
  }

  const capitalizeWord = (word) => {
    return `${word[0].toUpperCase()}${word.substring(1)}`
  }

  const handleSubmit = () => {
    if(contactSelectionAvailable && !contactType) {
      return
    }
    onConfirm(contactType || 'phone')
  }

  return (
      <div className="container">
        <div className="container container--hero small">
          <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" /* style="opacity:0" */ className="h3 h3--bold h3--blue title"> {`${t('appointment.summary_title')}`}<br /></h3>
          <h4 className="subtitle">{ t('appointment.summary_text') }</h4>
          <div className="appointment-summary">
            { selectedTopic !== 'other' && (
              <div>
                { selectedTopic !== 'other' && <h5><span className="field-title">{t('breadcrumb.legal_area')}: </span>{ topics[selectedTopic]?.title[currentLanguage()] }</h5> }
                { selectedTopic !== 'other' && <h5><span className="field-title">{t('breadcrumb.question')}: </span>{ questions[selectedQuestion]?.text[currentLanguage()]}</h5> }
              </div>
            )}
            <h5><span className="field-title">{t('breadcrumb.date_and_time')}: </span>{ printDate(selectedDate) }</h5>
            <h5><span className="field-title">{t('appointment.contact_type')}: </span>{ !contactSelectionAvailable && t('appointment.use_phone', { phone: userData?.contact?.phone }) }</h5>
            { contactSelectionAvailable &&
              <div className="contact-options">
                <label className="w-checkbox checkbox-field">
                  <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${contactType === 'phone' ? 'w--redirected-checked' : ''}`}></div>
                  <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required="" checked={contactType === 'phone'} onChange={handleContactTypeChange('phone')} style={{ opacity:0, position: 'absolute', zIndex: -1 }}/>
                  <span className="checkbox-label w-form-label">{ t('appointment.use_phone', { phone: userData?.contact?.phone }) }<br/></span>
                </label>
                <label className="w-checkbox checkbox-field">
                  <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${contactType === 'teams' ? 'w--redirected-checked' : ''}`}></div>
                  <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required="" checked={contactType === 'teams'} onChange={handleContactTypeChange('teams')} style={{ opacity:0, position: 'absolute', zIndex: -1 }}/>
                  <span className="checkbox-label w-form-label">{ t('appointment.use_teams', { email: userData?.contact?.email }) }<br/></span>
                </label>
              </div>
            }
          </div>
          <div className="button-wrapper">
            <div onClick={handleSubmit} className={`button${(contactSelectionAvailable && !contactType) ? ' disabled' : ''}`}>{t('appointment.confirm')}</div>
          </div>
        </div>
      </div>
  )
}



export default APStepSummary
