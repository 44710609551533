import config from '../config.json'
import { currentUserUid, userIdToken } from './auth'
import { baseUrl } from './api'
import { constants, currentLanguage } from '../utils'

const fetchIntentUrl = `${baseUrl()}/checkout/intent`

const fetchPaymentIntent = async (appointmentDraft, voucherCode, cardId) => {
  let response = await fetch(fetchIntentUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      environment: config.environment,
      project: constants.project_name[config.partner],
      appointmentDraft,
      clientId: currentUserUid(),
      cardId,
      voucherCode,
      language: currentLanguage()
    })
  })
  let responseJson = await response.json()
  // const secret = responseJson.clientSecret
  // const paymentId = responseJson.paymentId
  return responseJson
}

const validateVoucher = async (code, solution) => {
  let url = `${baseUrl()}/checkout/validateVoucher`
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      environment: config.environment,
      partnerId: config.partner,
      solution: solution,
      code: code.trim().toLowerCase()
    })
  })
  let responseJson = await response.json()
  return responseJson
}

const getCompanyData = async (vat, onError = () => {}) => {
  try {
    const url = `${baseUrl()}/api_${config.environment}/companyData`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        vat: vat,
        userId: currentUserUid()
      })
    });
    const data = await res.json();
    return data;
  } catch (err) {
    onError(err);
    return { error: err }
  }
}

const createFreeAppointmentRequest = async (appointmentData, code) => {
  try {
    const url = `${baseUrl()}/api_${config.environment}/createFreeRequest`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...appointmentData,
        // environment: config.environment,
        // voucher_code: code,
        author: currentUserUid()
      })
    });
    const data = await res.json();
    return data
  } catch (err) {
    console.log(err);
    return { error: err }
  }
}

const signOutBackend = async () => {
  try {
    let idToken = await userIdToken()
    let body = {
      idToken
    }
    const url = `${baseUrl()}/api_${config.environment}/signOut`
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = await res.json();
    return data
  } catch(err) {
    console.log(err)
    return { error: err }
  }
}

export {
  fetchPaymentIntent,
  validateVoucher,
  getCompanyData,
  createFreeAppointmentRequest,
  signOutBackend
}