import config from '../config.json'

const baseUrl = () => {

  switch(config.partner) {
    case 'lv':
      // return 'http://localhost:5001/call-legal-expert/europe-west1' 
      return 'https://europe-west1-call-legal-expert.cloudfunctions.net'
    case 'axa':
      // return 'http://localhost:5001/axa-legal-expert/europe-west1'
      return 'https://europe-west1-axa-legal-expert.cloudfunctions.net'
    case 'beci':
      // return 'http://localhost:5001/beci-legal-expert/europe-west1'
      return 'https://europe-west1-beci-legal-expert.cloudfunctions.net'
    default:
      return ''
  }
}

export {
  baseUrl
}