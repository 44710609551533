import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { t, currentLanguage, safeValue, isPhoneValid, isVatValid, isEmailValid, RECAPTCHA_SITE_KEY } from '../utils'
import { updateUserData } from '../services/database'
import { getCompanyData } from '../services/functions'
import { animateScroll as scroll } from 'react-scroll'
import config from '../config.json'
import { Loader } from './'

const APStepProfile = (props) => {

  const { onSave, history, refreshInteractions, showSnackbar, startLoading, stopLoading, fetchUserData, userDataFetched } = props

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [addressNumber, setAddressNumber] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressZip, setAddressZip] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [vat, setVat] = useState('')
  const [captchaValue, setCaptchaValue] = useState(null)

  useEffect(() => {
    refreshInteractions()
  }, [])


  useEffect(() => {
    setFirstName(safeValue(props.userData, 'name.first'))
    setLastName(safeValue(props.userData, 'name.last'))
    setEmail(safeValue(props.userData, 'contact.email'))
    setPhone(safeValue(props.userData, 'contact.phone'))
    setAddressStreet(safeValue(props.userData, 'address.street'))
    setAddressNumber(safeValue(props.userData, 'address.number'))
    setAddressCity(safeValue(props.userData, 'address.city'))
    setAddressZip(safeValue(props.userData, 'address.zip'))
    setVat(safeValue(props.userData, 'vat'))
    setOrganisation(safeValue(props.userData, 'organisation'))
    refreshInteractions()
  }, [props.userData])

  const saveChanges = async () => {
    setTimeout(() => {
      scroll.scrollTo(350)
    }, 1);
    if (!isDataComplete()) {
      showSnackbar({ text: t('account.data_incomplete'), color: 'error' })
      return
    }
    // if(!captchaValue) {
    //   showSnackbar({ text: t('auth.captcha'), color: 'error' })
    //   return 
    // }
    startLoading('updatingUser')
    let result = await updateUserData({
      name: {
        first: firstName,
        last: lastName
      },
      contact: {
        phone: phone,
        email: email
      },
      address: {
        street: addressStreet,
        number: addressNumber,
        city: addressCity,
        zip: addressZip
      },
      organisation: organisation,
      vat: vat
    })
    if (result.error) {
      showSnackbar({ text: result.error.message, color: 'error' })
    } else {
      if (config.partner === 'axa') {
        const axaClientData = await getCompanyData(vat, (err) => {
          console.log(err);
        });
        // console.log("axa client data", axaClientData)
        onSave(axaClientData.contractExists && axaClientData.eligible);
      } else {
        onSave()
      }
      fetchUserData()
    }
    stopLoading('updatingUser')
  }

  const isDataComplete = () => {
    if (!phone || !isPhoneValid(phone)) {
      return false
    }
    else if (!email || !isEmailValid(email)) {
      return false
    }
    if (!lastName) {
      return false
    }
    if (!firstName) {
      return false
    }
    if(config.partner === 'axa') {
      if (!addressCity) {
          return false
        }
      if (!addressStreet) {
        return false
      }
      if (!addressNumber) {
        return false
      }
      if (!addressZip) {
        return false
      }
      if (!organisation) {
        return false
      }
      if (!vat || !isVatValid(vat)) {
        return false
      }
    }
    return true
  }

  const fieldLabel = (title, required) => {
    return `${title}${required ? ' *' : ''}`
  }

  const renderHint = () => {
    switch (config.partner) {
      case 'lv':
      case 'beci':
        return (<span className="field-hint">{t('appointment.org_hint')}</span>)
      case 'axa':
        return null
      default:
        return null
    }
  }

  const fieldRequired = () => {
    switch (config.partner) {
      case 'lv': 
      case 'beci':
        return false
      case 'axa':
        return true
      default: 
        return false
    }
  }

  return (
    <div className="container">
      <div className="container container--hero small">
        <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" /* style="opacity:0" */ className="h3 h3--bold h3--blue"> {`${t('appointment.profile_info_title')}`}<br /></h3>
        {!userDataFetched && (
          <div className="profile-loader-wrapper">
            <Loader primary normal small />
          </div>
        )}
        {userDataFetched && (
          <div className="form w-form apstep-profile-wrapper">
            <h4 className="form__title form__title--mt">{t('appointment.contact_info')}</h4>
            <div className="field-wrap">
              <div className={`form__field-wrap form__field-wrap--ml ${!lastName ? 'invalid' : ''}`}>
                <label className="form__label">{fieldLabel(t('general.name_last'), true)}</label>
                <span className="field-hint">&nbsp;</span>
                <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="name_last" data-name="name_last" id="name_last" required="" />
              </div>
              <div className={`form__field-wrap form__field-wrap--ml ${!firstName ? 'invalid' : ''}`}>
                <label className="form__label">{fieldLabel(t('general.name_first'), true)}</label>
                <span className="field-hint">&nbsp;</span>
                <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="name_first" data-name="name_first" id="name_first" required="" />
              </div>
            </div>
            <div className="field-wrap">
              <div className={`form__field-wrap ${!isPhoneValid(phone) ? 'invalid' : ''}`}>
                <label htmlFor="N-de-t-l-phone" className="form__label">{fieldLabel(t('profile.phone'), true)}</label>
                <span className="field-hint">{t('appointment.phone_hint')}</span>
                <div className="input-with-adornment-wrapper">
                  <div className="form__text-field w-input input-adornment">{'+32'}</div>
                  <input value={phone} onChange={e => setPhone(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="phone" data-name="phone" id="phone" required="" />
                </div>
              </div>
              <div className={`form__field-wrap form__field-wrap--ml ${!isEmailValid(email) ? 'invalid' : ''}`}>
                <label htmlFor="Email-4" className="form__label">{fieldLabel(t('general.email'), true)}</label>
                <span className="field-hint">&nbsp;</span>
                <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="form__text-field w-input" maxLength="256" name="email" data-name="email" id="email" required="" />
              </div>
            </div>
            <h4 className="form__title form__title--mt">{t('profile.organisation')}</h4>
            {renderHint()}
            <div className="form__field-wrap width">
              <label htmlFor="Title-5" className="form__label">{fieldLabel(t('profile.organisation_name'), fieldRequired())}</label>
              <input value={organisation} onChange={e => setOrganisation(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="organistaion" data-name="organistaion" id="organistaion" required="" />
            </div>
            <div className="field-wrap">
              <div className="form__field-wrap">
                <label htmlFor="Adresse" className="form__label">{fieldLabel(t('profile.street'), fieldRequired())}</label>
                <input value={addressStreet} onChange={e => setAddressStreet(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="address" data-name="address" id="address" required="" />
              </div>
              <div className="form__field-wrap form__field-wrap--sm">
                <label htmlFor="Prenom-2" className="form__label">{fieldLabel(t('profile.number'), fieldRequired())}</label>
                <input value={addressNumber} onChange={e => setAddressNumber(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="address_number" data-name="address_number" id="address_number" required="" />
              </div>
            </div>
            <div className="field-wrap">
              <div className="form__field-wrap">
                <label htmlFor="Adresse-2" className="form__label">{fieldLabel(t('profile.city'), fieldRequired())}</label>
                <input value={addressCity} onChange={e => setAddressCity(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="address_city" data-name="address_city" id="address_city" required="" />
              </div>
              <div className="form__field-wrap form__field-wrap--sm">
                <label htmlFor="Prenom-3" className="form__label">{fieldLabel(t('profile.zip'), fieldRequired())}</label>
                <input value={addressZip} onChange={e => setAddressZip(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="address_zip" data-name="address_zip" id="address_zip" required="" />
              </div>
            </div>
            <div className={`form__field-wrap width`}>
              <label htmlFor="N-de-TVA" className="form__label">{fieldLabel(t('profile.vat'), fieldRequired())}</label>
              <div className="input-with-adornment-wrapper">
                <div className="form__text-field w-input input-adornment">{t('profile.vat_prefix')}</div>
                <input value={vat} onChange={e => setVat(e.target.value)} type="text" className="form__text-field w-input" maxLength="10" name="vat_number" data-name="vat_number" id="vat_number" required="" />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {userDataFetched && <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={(val) => setCaptchaValue(val)}
        hl={currentLanguage()}
      />} */}
      <div className="form__btn-wrap">
        {userDataFetched && <div onClick={saveChanges} className="link-box link-box--submit w-inline-block link-box--green profile_save_button">
          <p className="link-box__title">{t('general.save')}<br /></p>
        </div>}
      </div>
    </div>
  )
}

export default APStepProfile