import React, { useState, useEffect, Fragment } from 'react'
import Popover from '@material-ui/core/Popover';
import { animateScroll as scroll } from 'react-scroll'

import { currentUser } from '../services/auth'

import { t, languageFromLocale, currentLanguage } from '../utils'

import { Footer, Navbar } from '../components'

import config from '../config.json'
import { DescriptionOutlined, PersonOutlined, PersonOutlineRounded } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import { MdLogout, MdLogin, MdSupport } from 'react-icons/md'
import Header from '../components/UI/Header';

const NavigationPage = ({ showAuth, history, startLoading, stopLoading, refreshInteractions, userData, isLoading, setLocale, partner, signedIn, children }) => {

  const [email, setEmail] = useState('')
  const [currentPath, setCurrentPath] = useState('/')
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null)

  useEffect(() => {
    history.listen(location => {
      const { pathname } = location
      setCurrentPath(pathname)
    })
  }, [])

  const navigate = (path) => () => {
    history.push(path)
    closeAccountMenu()
  }

  const navigateAppointment = (path) => () => {
    history.push(path)
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    closeAccountMenu()
  }

  const openAccountMenu = (e) => {
    setAccountMenuAnchor(e.target)
  }

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null)
  }

  const openAuth = (content) => () => {
    showAuth(content)
  }

  useEffect(() => {
    const { pathname } = history.location
    setCurrentPath(pathname)
  }, [history.location.pathname])

  useEffect(() => {
    if(signedIn) {
      setEmail(currentUser()?.email)
    } else {
      setEmail('')
    }
  }, [signedIn])

  const userTitle = () => {
    if(userData && userData.name && userData.name.first) {
      return userData.name.first
    } else if(email) {
      return email
    } else {
      return ''
    }
  }


  const selectLocale = (locale) => () => {
    setLocale(locale)
    // closeLanguageMenu()
  }


  const renderLocaleLabel = (locale) => {
    const language = languageFromLocale(locale).long
    const country = locale.split('_')[1].toLowerCase()
    return (
      <div className="locale-label-wrapper">
        <img src={`/images/flag_${country}.png`} className="locale-label-image" alt={`${country} flag`} />
        <span className="locale-label">{ language }</span>
      </div>
    )
  }

  const renderLanguageSelection = () => {
    const locales = ['fr_BE', 'nl_BE']
    return locales.map((locale, li) => {
      return (
        <div
          key={`locale_${li}`}
          onClick={selectLocale(locale)}
          className={`nav-link--lang w-nav-link ${
            languageFromLocale(locale).code === currentLanguage()
              ? "highlighted"
              : ""
          }`}
        >
          {languageFromLocale(locale).code}
        </div>
      );
    })
  }

  const renderNavbar = () => {
    return (
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0a"
        className="navbar w-nav"
      >
        <div className="container">
          <div className="nav-wrap">
            <div onClick={navigate("/", true)} className="brand-link w-nav-brand">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={`/images/logo_large_beci.png`} className="navbar-logo" alt="" />
                  </div>
                  <div className="flip-card-back">
                    <p>{t('navbar.brand_text')}</p>
                  </div>
                </div>
              </div>              
            </div>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0f"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/")}
                  className={`nav-link nav-link w-nav-link${
                    currentPath === "/" ? " w--current" : ""
                  }`}
                >
                  {t("navbar.mle")}
                </div>
                <div className={`nav-line nav-line`}></div>
              </div>
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/appointment?location=appointment")}
                  className={`nav-link nav-link w-nav-link${
                    currentPath === "/appointment" ? " w--current" : ""
                  }`}
                >
                  {t("navbar.appointment")}
                </div>
                <div className={`nav-line nav-line`}></div>
              </div>
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/help")}
                  className={`nav-link nav-link w-nav-link${
                    currentPath === "/help" ? " w--current" : ""
                  }`}
                >
                  {t("navbar.help")}
                </div>
                <div className={`nav-line nav-line`}></div>
              </div>
            </nav>
            <div className="nav-link-login-menu-btn-wrap">
              <div className="menu-btn w-nav-button">
                <div className="menu-btn-img-wrapper">
                  <img
                    src={"/images/burger_menu_white.svg"}
                    alt="burger icon"
                    className="burger-icon"
                  />
                  <img src={"/images/x-2-white.svg"} alt="" className="close-icon" />
                </div>
              </div>
              <div className="nav-link-wrap--lang">
                { signedIn ?
                  <Fragment>
                    <div
                      onClick={openAccountMenu}
                      className="btn btn-nav-auth-fill"
                    >
                      <h4 className="nav__h">
                        {t("general.my_account")}
                      </h4>
                    </div>
                  </Fragment> : 
                  <div className="nav-auth-buttons">
                    <div
                      onClick={openAuth('login')}
                      className="btn btn-nav-auth"
                    >
                      <h4 className="nav__h">
                        { t("auth.sign_in")}
                      </h4>
                    </div>
                    <div className="vertical-separator" />
                    <div
                      onClick={openAuth('register')}
                      className="btn btn-nav-auth"
                    >
                      <h4 className="nav__h">
                        { t("auth.sign_up")}
                      </h4>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="add-question-menu"
          anchorEl={accountMenuAnchor}
          keepMounted
          open={Boolean(accountMenuAnchor)}
          onClose={closeAccountMenu}
          className={`account-menu`}
        >
          <MenuItem dense onClick={navigateAppointment('/appointment?location=appointment')}>
            { t('navbar.appointment') }
          </MenuItem>
          <MenuItem dense onClick={navigate('/appointments')}>
            {/* <DescriptionOutlined /> */}
            { t('account.appointments') }
          </MenuItem>
          <MenuItem dense onClick={navigate('/profile')}>
            {/* <PersonOutlined /> */}
            { t('account.profile') }
          </MenuItem>
        </Menu>
      </div>
    )
  }

  return (
    <>
      <div className="wrapper">
          <Header navbar={renderNavbar} navigate={navigate} languages={renderLanguageSelection} accountMenuAnchor={accountMenuAnchor} closeAccountMenu={closeAccountMenu} openProfile={() => {}} openMyDocuments={() => {}} doSignOut={() => {}} />
        <div className="sec">
          { children }
        </div>
      </div>
      <Footer history={history} partner={partner} refreshInteractions={refreshInteractions} />
    </>
  )
}

export default NavigationPage