import React, { useState, useEffect } from 'react'
import { t, currentLanguage } from '../utils'

const Help = (props) => {

  const { refreshInteractions, partner } = props
  
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    if(questions.length > 0) {
      refreshInteractions()
    }
  }, [questions])


  useEffect(() => {
    if(!partner) {
      return
    }
    if(partner.faqs) {
      setQuestions(partner.faqs)
    }
  }, [partner])

  const renderQuestion = (question, qi) => {
    return (
      <div key={`faq_${qi}`} className="accordion-wrapper">
        <div className="help-content">
          <div data-w-id="dd2cc323-b524-7451-102d-767c73b2af80" className="help-title">
            <h4 className="help-h4">{ question.question[currentLanguage()] } </h4><img src="images/right-arrow.svg" alt="right orange arrow" width="10" height="16" className="faq-down-arrow"/></div>
          <div className="help-text-wrap">
            <div className="help-text">{ question.answer[currentLanguage()] }</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="content-wrap">
        <div className="help-wrap">
          <h4 className="form__title">{ t('navbar.help') }</h4>
          { questions.map(renderQuestion) }
          <div className="help-contact">
            <img src="images/mail.svg" alt="" className="mail-img"/>
            <div>{ t('help.another_question') } <br/> <div>{ t('help.contact_us') } <a href='mailto:info@beci.be' target='_blank' className="mail-link">info@beci.be</a></div> </div> 
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Help