import React from 'react'

import { t } from '../utils'

const PrivacyPolicy = () => {
  let address = t('privacy_page.sec_9_address', { link: `<a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>`}).split('\n').map(text => `<span>${text}</span>`).join('')
  let sec9para4 = t('privacy_page.sec_9_para_4', { link: `<a href="https://www.autoriteprotectiondonnees.be">https://www.autoriteprotectiondonnees.be</a>` })
  let sec10para1 = t('privacy_page.sec_10_para_1', { link: `<a href="mailto:info@beci.be">info@beci.be</a>` })

  return (
    <div className="sec privacy-wrapper">
      <div className="container">
        <div className="privacy-page">
          <h1>{t('privacy_page.title')}</h1>
          <p className="subtitle">{t('privacy_page.subtitle')}</p>

          <section>
            <h2 className="underline">{t('privacy_page.sec_1_head_1')}</h2>
            <p>{t('privacy_page.sec_1_para_1')}</p>
            <p>{t('privacy_page.sec_1_para_2')}</p>
            <p>{t('privacy_page.sec_1_para_3')}</p>
            <p>{t('privacy_page.sec_1_para_4')}</p>
            <p>{t('privacy_page.sec_1_para_5')}</p>
            <p>{t('privacy_page.sec_1_para_6')}</p>
            <p>{t('privacy_page.sec_1_para_7')}</p>
            <p>{t('privacy_page.sec_1_para_8')}</p>
            <p>{t('privacy_page.sec_1_para_9')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_2_head_1')}</h2>
            <table>
              <thead>
                <tr>
                  <th>{t('privacy_page.sec_2_th_1')}</th>
                  <th>{t('privacy_page.sec_2_th_2')}</th>
                  <th>{t('privacy_page.sec_2_th_3')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('privacy_page.sec_2_td_1')}</td>
                  <td>{t('privacy_page.sec_2_td_2')}</td>
                  <td>{t('privacy_page.sec_2_td_3').split('\n').map((text, i) => <span key={i}>{text}</span>)}</td>
                </tr>
                <tr>
                  <td>{t('privacy_page.sec_2_td_4')}</td>
                  <td>{t('privacy_page.sec_2_td_5')}</td>
                  <td>{t('privacy_page.sec_2_td_6').split('\n').map((text, i) => <span key={i}>{text}</span>)}</td>
                </tr>
              </tbody>
            </table>
            <h2>{t('privacy_page.sec_2_head_2')}</h2>
            <p>{t('privacy_page.sec_2_para_1')}</p>
            <p>{t('privacy_page.sec_2_para_2')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_3_head_1')}</h2>
            <p>{t('privacy_page.sec_3_para_1')}</p>
            <p>{t('privacy_page.sec_3_para_2')}</p>
            <p>{t('privacy_page.sec_3_para_3')}</p>
            <p>{t('privacy_page.sec_3_para_4')}</p>
            <p className="underline">{t('privacy_page.sec_3_para_5')}</p>
            <ul className="list">
              <li>{t('privacy_page.sec_3_litem_1')}</li>
              <li>{t('privacy_page.sec_3_litem_2')}</li>
              <li>{t('privacy_page.sec_3_litem_3')}</li>
              <li>{t('privacy_page.sec_3_litem_4')}</li>
              <li>{t('privacy_page.sec_3_litem_5')}</li>
            </ul>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_4_head_1')}</h2>
            <p>{t('privacy_page.sec_4_para_1')}</p>
            <p>{t('privacy_page.sec_4_para_2')}</p>
            <p>{t('privacy_page.sec_4_para_3')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_5_head_1')}</h2>
            <p>{t('privacy_page.sec_5_para_1')}</p>
            <ul className="list">
              <li>{t('privacy_page.sec_5_litem_1')}</li>
              <li>{t('privacy_page.sec_5_litem_2')}</li>
              <li>{t('privacy_page.sec_5_litem_3')}</li>
            </ul>
            <p>{t('privacy_page.sec_5_para_2')}</p>
          </section>

          <section>
            <h2 className="highlighted">{t('privacy_page.sec_6_pre_head_1')}</h2>
            <h2 className="underline">{t('privacy_page.sec_6_head_1')}</h2>
            <p>{t('privacy_page.sec_6_para_1')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_1')}</h3>
            <p>{t('privacy_page.sec_6_para_2')}</p>
            <p>{t('privacy_page.sec_6_para_3')}</p>
            <p>{t('privacy_page.sec_6_para_4')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_2')}</h3>
            <p>{t('privacy_page.sec_6_para_5')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_3')}</h3>
            <p>{t('privacy_page.sec_6_para_6')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_4')}</h3>
            <p>{t('privacy_page.sec_6_para_7')}</p>
            <ul className="list">
              <li>{t('privacy_page.sec_6_litem_1')}</li>
              <li>{t('privacy_page.sec_6_litem_2')}</li>
              <li>{t('privacy_page.sec_6_litem_3')}</li>
              <li>{t('privacy_page.sec_6_litem_4')}</li>
              <li>{t('privacy_page.sec_6_litem_5')}</li>
              <li>{t('privacy_page.sec_6_litem_6')}</li>
            </ul>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_5')}</h3>
            <p>{t('privacy_page.sec_6_para_8')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_6')}</h3>
            <p>{t('privacy_page.sec_6_para_9')}</p>
            <p>{t('privacy_page.sec_6_para_10')}</p>
            <ul className="list">
              <li>{t('privacy_page.sec_6_litem_7')}</li>
              <li>{t('privacy_page.sec_6_litem_8')}</li>
            </ul>
            <p>{t('privacy_page.sec_6_para_11')}</p>
            <h3 className="indented">{t('privacy_page.sec_6_subhead_7')}</h3>
            <p>{t('privacy_page.sec_6_para_12')}</p>
            <p>{t('privacy_page.sec_6_para_13')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_7_head_1')}</h2>
            <h3 className="indented">{t('privacy_page.sec_7_subhead_1')}</h3>
            <p>{t('privacy_page.sec_7_para_1')}</p>
            <p>{t('privacy_page.sec_7_para_2')}</p>
            <p>{t('privacy_page.sec_7_para_3')}</p>
            <h3 className="indented">{t('privacy_page.sec_7_subhead_2')}</h3>
            <p>{t('privacy_page.sec_7_para_4')}</p>
            <p>{t('privacy_page.sec_7_para_5')}</p>
          </section>

          <section>
            <h2 className="highlighted">{t('privacy_page.sec_8_pre_head_1')}</h2>
            <h2 className="underline">{t('privacy_page.sec_8_head_1')}</h2>
            <p>{t('privacy_page.sec_8_para_1')}</p>
            <p>{t('privacy_page.sec_8_para_2')}</p>
            <p>{t('privacy_page.sec_8_para_3')}</p>
            <p>{t('privacy_page.sec_8_para_4')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_9_head_1')}</h2>
            <p>{t('privacy_page.sec_9_para_1')}</p>
            <p>{t('privacy_page.sec_9_para_2')}</p>
            <address dangerouslySetInnerHTML={{ __html: address }}></address>
            <p>{t('privacy_page.sec_9_para_3')}</p>
            <p dangerouslySetInnerHTML={{ __html: sec9para4 }}></p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_10_head_1')}</h2>
            <p dangerouslySetInnerHTML={{ __html: sec10para1 }}></p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_11_head_1')}</h2>
            <p>{t('privacy_page.sec_11_para_1')}</p>
          </section>

          <section>
            <h2 className="underline">{t('privacy_page.sec_12_head_1')}</h2>
            <p>{t('privacy_page.sec_12_para_1')}</p>
            <p>{t('privacy_page.sec_12_para_2')}</p>
            <p>{t('privacy_page.sec_12_para_3')}</p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy