import React, { Fragment } from 'react'
import { EmailOutlined, PhoneOutlined, Facebook, LinkedIn, Twitter } from '@material-ui/icons'

const Header = ({ navbar, languages }) => {

  return (
    <Fragment>
      <div className={`sec-wrap--beci header-top`}>
        <div className="container">
          <div className="header-top_inner">
            <div className="languages">
              { languages() }
            </div>
            <ul className="social">
              <li><a href="https://www.linkedin.com/company/beci/?originalSubdomain=fr" target="_blank" rel="noopener noreferrer"><LinkedIn /></a></li>
              <li><a href="https://www.facebook.com/beci.brussels/" target="_blank" rel="noopener noreferrer"><Facebook /></a></li>
              <li><a href="https://twitter.com/beci_brussels" target="_blank" rel="noopener noreferrer"><Twitter /></a></li>
            </ul>
            <ul className="contacts">
              <li><a href="mailto:info@beci.be" target="_blank" rel="noopener noreferrer"><EmailOutlined /> <span>info@beci.be</span></a></li>
              <li><a href="tel:026485002" target="_blank" rel="noopener noreferrer"><PhoneOutlined /> <span>02/648.50.02</span></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`sec-wrap--beci header-bottom`}>
        { navbar(true, true) }
      </div>
    </Fragment>
  )
}

export default Header