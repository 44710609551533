import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
// import Route from './Route'
import {
  AppointmentPlanning,
  Profile,
  Home,
  AppointmentConfirmation,
  About,
  Experts,
  Cookies,
  LandingOne,
  LandingTwo,
  LandingThree,
  PrivacyPolicy,
  Terms
} from '../pages'

import {
  Placeholder
} from '../components'
import Help from '../pages/Help'
import config from '../config.json'


const defaultUnauthorizedPath = '/'
const defaultAuthorizedPath = '/'

const privatePaths = [
  '/appointments',
  '/profile',
  '/dashboard'
]

const onlyUnauthorizedPaths = [
]

const isPathPrivate = (path) => {
  return privatePaths.indexOf(path) !== -1
}

const isPathOnlyUnauthorized = (path) => {
  return onlyUnauthorizedPaths.indexOf(path) !== -1
}

const Routes = (props) => {
  return (
    <Switch>
      <Route {...props} path="/appointment" render={(p) => <AppointmentPlanning {...p} {...props} />} isPrivate={isPathPrivate('/appointment')} />
      <Route {...props} path="/appointment-confirmed" render={(p) => <AppointmentConfirmation {...p} {...props} />} isPrivate={isPathPrivate('/appointment-confirmed')} />
      <Route {...props} path="/dashboard" render={(p) => <Profile {...p} {...props} />} isPrivate={isPathPrivate('/dashboard')} />
      <Route {...props} path="/profile" render={(p) => <Profile {...p} {...props} />} isPrivate={isPathPrivate('/profile')} />
      <Route {...props} path="/appointments" render={(p) => <Profile {...p} {...props} />} isPrivate={isPathPrivate('/appointments')} />
      <Route {...props} path="/about" render={(p) => <About {...p} {...props} />} isPrivate={isPathPrivate('/about')} />
      <Route {...props} path="/help" render={(p) => <Help {...p} {...props} />} isPrivate={isPathPrivate('/help')} />
      <Route {...props} path="/privacy-policy" render={(p) => <PrivacyPolicy {...p} {...props} />} isPrivate={isPathPrivate('/privacy-policy')} />
      <Route {...props} path="/terms" render={(p) => <Terms {...p} {...props} />} isPrivate={isPathPrivate('/terms')} />
      <Route {...props} path="/experts" render={(p) => <Experts {...p} {...props} />} isPrivate={isPathPrivate('/experts')} />
      <Route {...props} path="/cookies" render={(p) => <Cookies {...p} {...props} />} isPrivate={isPathPrivate('/cookies')} />
      <Route {...props} path="/1" render={(p) => <LandingOne {...p} {...props} />} isPrivate={isPathPrivate('/1')} />
      <Route {...props} path="/2" render={(p) => <LandingTwo {...p} {...props} />} isPrivate={isPathPrivate('/2')} />
      <Route {...props} path="/3" render={(p) => <LandingThree {...p} {...props} />} isPrivate={isPathPrivate('/3')} />
      <Route {...props} path="/" render={(p) => <Home {...p} {...props} />} isPrivate={isPathPrivate('/')} />
      <Route render={() => (
        <Redirect to="/"/>
      )}/>
      {/* <Route {...props} component={Home} isPrivate={isPathPrivate('/')} isNavigatorPage={isNavigatorPage('/')} pages={navigatorPages} /> */}
      {/* <Route {...props} component={() => <Redirect to={'/'} /> } /> */}
    </Switch>
  )
}

export default Routes

export {
  defaultAuthorizedPath,
  defaultUnauthorizedPath,
  isPathPrivate,
  isPathOnlyUnauthorized
}