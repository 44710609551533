import React, { useState, useEffect } from 'react'

import { Placeholder, ProfileTabInfo, ProfileTabDashboard, ProfileTabAppointments } from '../components' 
import { t } from '../utils'

const Profile = (props) => {

  const { history, topics, questions, appointments, startLoading, stopLoading, userData, showSnackbar, refreshInteractions, fetchUserData, fetchAppointments, partner } = props

  const [content, setContent] = useState('general')

  useEffect(() => {
    // url query changed
    const path = history.location.pathname
    if(path === '/appointments') {
      setContent('appointments')
    } else if(path === '/dashboard') {
      setContent('dashboard')
    } else if(path === '/profile') {
      setContent('profile')
    }
  }, [history.location])



  useEffect(() => {
    refreshInteractions()
  }, [content])

  const renderContent = () => {
    switch(content) {
      case 'dashboard': 
      return <ProfileTabDashboard startLoading={startLoading} stopLoading={stopLoading} history={history} topics={topics} questions={questions} appointments={appointments} fetchAppointments={fetchAppointments} partner={partner} refreshInteractions={refreshInteractions} />
      case 'profile': 
        return <ProfileTabInfo startLoading={startLoading} stopLoading={stopLoading} userData={userData} showSnackbar={showSnackbar} fetchUserData={fetchUserData} />
      case 'appointments':
        return <ProfileTabAppointments topics={topics} questions={questions} appointments={appointments} startLoading={startLoading} stopLoading={stopLoading} fetchAppointments={fetchAppointments} />
      default:
        return <Placeholder title="TODO" />
    }
  }

  const navigate = (path) => () => {
    history.push(path)
  }

  const current = (tab) => {
    return tab === content ? 'w--current' : ''
  }

  return (
    <div className="container">
      <div className="tab-wrap">
        <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
          <div className="tabs__menu w-tab-menu">
            {/* <a onClick={navigate('/dashboard')} className={`tabs__link w-inline-block w-tab-link ${current('dashboard')}`}>
              <div className="tabs__link-title">{ t('account.dashboard') }</div>
            </a> */}
            <div onClick={navigate('/appointments')} className={`tabs__link w-inline-block w-tab-link ${current('appointments')}`}>
              <div className="tabs__link-title">{ t('account.appointments') }</div>
            </div>
            <div onClick={navigate('/profile')} className={`tabs__link w-inline-block w-tab-link ${current('profile')}`}>
              <div className="tabs__link-title">{ t('account.profile') }</div>
            </div>
          </div>
          <div className="tabs__content w-tab-content">
            { renderContent() }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile