import React, { useState, useEffect, useContext } from 'react'
import { APStepTopic, APStepQuestion, APStepDetail, APStepSolution, APStepCheckout, APStepProfile, APStepSummary, Breadcrumb } from '../components'
import { paramsFromSearch, updateUrlParams, t, currentLanguage, paramsArrayFromSearch, searchStringFromParamsArray } from '../utils'
import { uploadFile } from '../services/database'
import { createFreeAppointmentRequest } from '../services/functions'
import config from '../config.json'
import { currentUserUid } from '../services/auth'
import { animateScroll as scroll } from 'react-scroll'
import moment from 'moment'
import { GlobalContext } from '../context'

const AppointmentPlanning = (props) => {

  const { topics, questions, history, startLoading, stopLoading, showSnackbar, showAuth, refreshInteractions, fetchAppointments, fetchUserData, partner, userData, userDataFetched } = props
  const { setSelectedGlobalExpert } = useContext(GlobalContext)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [profile, setProfile] = useState(false)
  // const [solution, setSolution] = useState(false)
  // const [selectedSolution, setSelectedSolution] = useState(null)
  // const [checkout, setCheckout] = useState(false)
  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState([])
  const [date, setDate] = useState(null)
  // const [voucherCode, setVoucherCode] = useState('')
  // const [reducedPrice, setReducedPrice] = useState(-1)
  const [dataReady, setDataReady] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedExpert, setSelectedExpert] = useState(null)
  const [summary, setSummary] = useState(false)

  useEffect(() => {
    let params = paramsFromSearch(history.location.search)
    if(params.topic) {
      setSelectedTopic(params.topic)
    } else {
      setSelectedTopic(null)
    }
    if(params.question) {
      setSelectedQuestion(params.question)
    } else {
      setSelectedQuestion(null)
    }
    if(params.location) {
      setSelectedLocation(params.location)
    } else {
      setSelectedLocation(null)
    }
    if(params.expert && !selectedExpert) {
      setSelectedExpert(params.expert)
    }
    if(params.summary) {
      setSummary(true)
    } else {
      setSummary(false)
    }
    setProfile(Boolean(params.profile))
    setDataReady(true)
  }, [history.location, props.topics, props.questions])

  const selectTopic = (t) => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    updateUrlParams(history, { topic: t.id })
  }

  const selectQuestion = (q) => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    updateUrlParams(history, { question: q.id })
  }

  const currentStep = () => {
    if(summary && !!selectedTopic && !!selectedQuestion && !!date) {
      return 'summary'
    } else if(profile && !!selectedTopic && !!selectedQuestion && !!date) {
      return 'profile'
    } else if(!!selectedTopic && !!selectedQuestion) {
      return 'details'
    } else if(!!selectedTopic) {
      return 'question'
    } else {
      return 'topic'
    }
  }

  const onDetailSubmit = (c, atts, d) => {
    setComment(c)
    setAttachments([...atts])
    setDate(d)
    updateUrlParams(history, { profile: true })
  }

  const createAppointmentDraft = async (contactType) => {
    startLoading('creatingDraft')
    const now = moment().valueOf()
    let data = {
      contact_type: contactType,
      contact: userData.contact,
      topic: selectedTopic,
      question: selectedQuestion,
      comment,
      date,
      // solutionId: partner.solutions[selectedSolutionIndex].id,
      language: currentLanguage(),
      meta: {
        created: now,
        updated: now
      }
    }
    // if(selectedExpert) {
    //   data.expert = emailForExpert(selectedExpert)      
    // }
    if(!!attachments && attachments.length > 0) {
      data.attachments = []
      for(let i in attachments) {
        let att = attachments[i]
        if(att.file) {
          let uploadResult = await uploadFile(att.file, `${config.environment}_${att.name}`, `users/${currentUserUid()}/attachments`)
          let responseJson = await uploadResult.json()
          if(!responseJson.error) {
            data.attachments.push({
              name: att.name,
              url: responseJson.url
            })
          } else {
            console.log('Upload error', responseJson)
          }
        } else if(att.url) {
          data.attachments.push(att)
        }
      }
    }
    stopLoading('creatingDraft')
    return data
  }

  // const emailForExpert = (expertName) => {
  //   for(let e of partner.experts) {
  //     if(e.name === expertName) {
  //       return e.email
  //     }
  //   }
  //   return ''
  // }

  const onExpertChange = (expertName, expert) => {
    if(expertName === selectedExpert) {
      return
    }
    let params = paramsArrayFromSearch(history.location.search)
    for(let i in params) {
      if(params[i].key === 'expert') {
        params[i].value = expertName
      }
    }
    // history.location.search = searchStringFromParamsArray(params)
    history.replace({ pathname: history.location.pathname, search: searchStringFromParamsArray(params) }); 
    setSelectedExpert(expertName)  
    setSelectedGlobalExpert(expert)    
  }

  const onNavigate = (path) => {
    history.push(path)
  }

  const onSaveProfile = () => {
    updateUrlParams(history, { summary: true })
  }

  const onSummaryConfirmed = async (contactType) => {
    startLoading('creatingAppointment')

    const draftData = await createAppointmentDraft(contactType);
    let requestResult = await createFreeAppointmentRequest(draftData)

    if(requestResult.error) {
      console.log(requestResult.error)
      showSnackbar({ text: requestResult.error.message, color: 'error' })
      stopLoading('creatingAppointment')
      return
    }    
    await fetchAppointments()
    
    stopLoading('creatingAppointment')

    history.replace('/appointment-confirmed', {
      appointment: {
        topic: selectedTopic,
        question: selectedQuestion,
        comment,
        date
      },
    })
  }

  const renderStep = () => {
    switch(currentStep()) {
      case 'topic': 
        return <APStepTopic 
                topics={topics}
                onTopicSelect={selectTopic}
                history={history}
                refreshInteractions={refreshInteractions} />
      case 'question':
        return <APStepQuestion
                topics={topics}
                questions={questions}
                selectedTopic={selectedTopic}
                onQuestionSelect={selectQuestion}
                refreshInteractions={refreshInteractions} />
      case 'details':
        return <APStepDetail
                topics={topics}
                questions={questions}
                selectedQuestion={selectedQuestion}
                showAuth={showAuth}
                showSnackbar={showSnackbar}
                onSubmit={onDetailSubmit}
                refreshInteractions={refreshInteractions}
                partner={partner} 
                selectedExpert={selectedExpert} 
                onExpertChange={onExpertChange}
                history={history} />
      case 'profile':
        return <APStepProfile
                onSave={onSaveProfile}
                userData={userData}
                showSnackbar={showSnackbar}
                startLoading={startLoading}
                stopLoading={stopLoading}
                fetchUserData={fetchUserData}
                refreshInteractions={refreshInteractions} 
                userDataFetched={userDataFetched} />
      // case 'solution':
      //   return <APStepSolution
      //           onSolutionSelected={onSolutionSelected}
      //           partner={partner}
      //           refreshInteractions={refreshInteractions}
      //           showSnackbar={showSnackbar}
      //           startLoading={startLoading}
      //           stopLoading={stopLoading}
      //           onVoucherApplied={onVoucherApplied}
      //           userData={userData} />
      // case 'checkout':
      //   return <APStepCheckout
      //           showSnackbar={showSnackbar}
      //           startLoading={startLoading}
      //           stopLoading={stopLoading}
      //           showAuth={showAuth}
      //           userData={userData}
      //           voucherCode={voucherCode}
      //           refreshInteractions={refreshInteractions}
      //           onCheckoutSubmit={onCheckoutSubmitted}
      //           onCheckoutSuccess={onCheckoutSuccess} />
      case 'summary':
        return <APStepSummary
                topics={topics}
                questions={questions}
                selectedQuestion={selectedQuestion}
                selectedTopic={selectedTopic}
                selectedDate={date}
                showSnackbar={showSnackbar}
                partner={partner} 
                selectedExpert={selectedExpert} 
                history={history}
                userData={userData}
                onConfirm={onSummaryConfirmed}
                />
      default:
        return <div></div>
    }
  }

  const renderBreadcrumbs = () => {
    let path;
    path = [{ label: '', url: '/' }]
    if(selectedLocation) {
      if(selectedLocation === 'appointment') {
        path.push({ label: t('navbar.appointment'), url: `/appointment?location=appointment` })
      }
      if(selectedTopic && selectedLocation !== 'skip') {
        let label = t('breadcrumb.legal_area'), label2 = t('breadcrumb.question')

        if(selectedLocation === 'home') {
          path.push({ label, url: `/` })
        }
        path.push({ label: label2, url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}` })
      }
      if(selectedQuestion) {
        let label = t('breadcrumb.date_and_time')

        path.push({ label, url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}` })
      }
      if(profile && !!date) {
        path.push({ label: t('breadcrumb.contact'), url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}&profile=true`})
      }
    }

    return (
      <Breadcrumb path={path} navigate={onNavigate} />
    )
  }

  return (
    <div className="container container--hero container--with-breadcrumbs">
      { renderBreadcrumbs() }
      { dataReady && renderStep() }
    </div>
  )
}

export default AppointmentPlanning